<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card mt-5">
          <div class="card-body">
            <h3 class="card-title text-center">خروج از حساب کاربری</h3>
            <p class="text-center">اکنون شما از حساب خود خارج شده اید.</p>
            <button @click="goToLogin" class="btn btn-primary w-100">می‌توانید از اینجا دوباره به حساب خود وارد شوید</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserLogout',
  mounted() {
    localStorage.removeItem('jwt');
    localStorage.removeItem('username');
    setTimeout(() => {
      this.$router.push('/login');
    }, 1); // Redirect after 2 seconds
  },
  methods: {
    goToLogin() {
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.container {
  margin-top: 50px;
}
</style>
