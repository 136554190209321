<template>
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <div class="card">
          <div class="card-header">
            <ul class="nav nav-tabs card-header-tabs" data-bs-toggle="tabs" role="tablist">
              <li class="nav-item" role="presentation">
                <a href="#tabs-month" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">ماه</a>
              </li>
              <li class="nav-item" role="presentation">
                <a href="#tabs-week" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab">هفته</a>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <div class="tab-content">
              <div class="tab-pane active show" id="tabs-month" role="tabpanel">
                <h4>تراکنش‌های داخل ایران (ماه)</h4>
                <div class="legend-container">
                  <div class="legend-item">
                    <span class="legend-color" style="margin-left:3px;background-color: rgba(75, 192, 192, 1);"></span>
                    <span> تراکنش های موفق ماه جاری: {{ formatNum(currentMonthTotalDomestic) }}</span>
                  </div>
                  <div class="legend-item">
                    <span class="legend-color" style="margin-left:3px;background-color: rgba(192, 192, 192, 1);"></span>
                    <span> تراکنش های موفق ماه گذشته: {{ formatNum(previousMonthTotalDomestic) }}</span>
                  </div>
                  <div class="legend-item"> <!-- Add this block in ماه tab for تراکنش‌های داخل ایران -->
                    <span class="legend-color" style="margin-left:3px;background-color: rgba(0, 128, 0, 1);"></span>
                    <span> تعداد تراکنش‌های موفق روز: {{ formatNum(dailyTomanTransactions) }}</span>
                  </div>
                </div>
                <div class="chart-container">
                  <LineChart :data="accumulativeTransactionsDataDomesticMonth" :options="monthChartOptions" v-if="accumulativeTransactionsDataDomesticMonth"/>
                </div>
                <h4>تراکنش‌های خارج از کشور (ماه)</h4>
                <div class="legend-container">
                  <div class="legend-item">
                    <span class="legend-color" style="margin-left:3px;background-color: rgba(75, 192, 192, 1);"></span>
                    <span> تراکنش های موفق ماه جاری: {{ formatNum(currentMonthTotalAbroad) }}</span>
                  </div>
                  <div class="legend-item">
                    <span class="legend-color" style="margin-left:3px;background-color: rgba(192, 192, 192, 1);"></span>
                    <span> تراکنش های موفق ماه گذشته: {{ formatNum(previousMonthTotalAbroad) }}</span>
                  </div>

                  <div class="legend-item"> <!-- Add this block in ماه tab for تراکنش‌های خارج از کشور -->
                    <span class="legend-color" style="margin-left:3px;background-color: rgba(0, 128, 0, 1);"></span>
                    <span> تعداد تراکنش‌های موفق روز: {{ formatNum(dailyEuroTransactions) }}</span>
                  </div>
                </div>
                <div class="chart-container">
                  <LineChart :data="accumulativeTransactionsDataAbroadMonth" :options="monthChartOptions" v-if="accumulativeTransactionsDataAbroadMonth"/>
                </div>
              </div>
              <div class="tab-pane" id="tabs-week" role="tabpanel">
                <h4>تراکنش‌های داخل ایران (هفته)</h4>
                <div class="legend-container">
                  <div class="legend-item">
                    <span class="legend-color" style="margin-left:3px;background-color: rgba(75, 192, 192, 1);"></span>
                    <span> تراکنش های موفق هفته جاری: {{ formatNum(currentWeekTotalDomestic) }}</span>
                  </div>
                  <div class="legend-item">
                    <span class="legend-color" style="margin-left:3px;background-color: rgba(192, 192, 192, 1);"></span>
                    <span> تراکنش های موفق هفته گذشته: {{ formatNum(previousWeekTotalDomestic) }}</span>
                  </div>
                  <div class="legend-item"> <!-- Add this block in ماه tab for تراکنش‌های داخل ایران -->
                    <span class="legend-color" style="margin-left:3px;background-color: rgba(0, 128, 0, 1);"></span>
                    <span> تعداد تراکنش‌های موفق روز: {{ formatNum(dailyTomanTransactions) }}</span>
                  </div>
                </div>
                <div class="chart-container">
                  <LineChart :data="accumulativeTransactionsDataDomesticWeek" :options="weekChartOptions" v-if="accumulativeTransactionsDataDomesticWeek"/>
                </div>
                <h4>تراکنش‌های خارج از کشور (هفته)</h4>
                <div class="legend-container">
                  <div class="legend-item">
                    <span class="legend-color" style="margin-left:3px;background-color: rgba(75, 192, 192, 1);"></span>
                    <span> تراکنش های موفق هفته جاری: {{ formatNum(currentWeekTotalAbroad) }}</span>
                  </div>
                  <div class="legend-item">
                    <span class="legend-color" style="margin-left:3px;background-color: rgba(192, 192, 192, 1);"></span>
                    <span> تراکنش های موفق هفته گذشته: {{ formatNum(previousWeekTotalAbroad) }}</span>
                  </div>

                  <div class="legend-item"> <!-- Add this block in ماه tab for تراکنش‌های خارج از کشور -->
                    <span class="legend-color" style="margin-left:3px;background-color: rgba(0, 128, 0, 1);"></span>
                    <span> تعداد تراکنش‌های موفق روز: {{ formatNum(dailyEuroTransactions) }}</span>
                  </div>
                </div>
                <div class="chart-container">
                  <LineChart :data="accumulativeTransactionsDataAbroadWeek" :options="weekChartOptions" v-if="accumulativeTransactionsDataAbroadWeek"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="card">
          <div class="card-header">
            <ul class="nav nav-tabs card-header-tabs" data-bs-toggle="tabs" role="tablist">
              <li class="nav-item" role="presentation">
                <a href="#tabs-domestic" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">تراکنش‌های داخل</a>
              </li>
              <li class="nav-item" role="presentation">
                <a href="#tabs-abroad" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab">تراکنش‌های خارج</a>
              </li>

              <li class="nav-item  ms-auto" role="presentation">
                <router-link to="/transactions" class="nav-link" aria-selected="false" tabindex="-1">همه تراکنش‌ها</router-link>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <div class="tab-content">
              <div class="tab-pane active show" id="tabs-domestic" role="tabpanel">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">نام</th>
                        <th scope="col">نام محصول</th>
                        <th scope="col">مقدار</th>
                        <th scope="col">واحد</th>
                        <th scope="col">تاریخ</th>
                        <th scope="col">ساعت</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="transaction in paginatedTransactionsDomestic" :key="transaction.id">
                        <td>{{ transaction.name }}</td>
                        <td>{{ transaction.product_name }}</td>
                        <td>{{ formatNum(transaction.amount) }}</td>
                        <td>{{ euroOrTomans(transaction.abroad) }}</td>
                        <td>{{ splitCreatedAt(transaction.created_at, 1) }}</td>
                        <td>{{ splitCreatedAt(transaction.created_at, 0) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane" id="tabs-abroad" role="tabpanel">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">نام</th>
                        <th scope="col">نام محصول</th>
                        <th scope="col">مقدار</th>
                        <th scope="col">واحد</th>
                        <th scope="col">تاریخ</th>
                        <th scope="col">ساعت</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="transaction in paginatedTransactionsAbroad" :key="transaction.id">
                        <td>{{ transaction.name }}</td>
                        <td>{{ transaction.product_name }}</td>
                        <td>{{ formatNum(transaction.amount) }}</td>
                        <td>{{ euroOrTomans(transaction.abroad) }}</td>
                        <td>{{ splitCreatedAt(transaction.created_at, 1) }}</td>
                        <td>{{ splitCreatedAt(transaction.created_at, 0) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <br><br>
  </div>
</template>

<script>
import axios from 'axios';
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale, Filler } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale, Filler);

export default {
  name: 'AnalyticalCharts',
  components: {
    LineChart: Line
  },
  data() {
    return {
      loaded: false,
      accumulativeTransactionsDataDomesticMonth: null,
      accumulativeTransactionsDataAbroadMonth: null,
      accumulativeTransactionsDataDomesticWeek: null,
      accumulativeTransactionsDataAbroadWeek: null,
      currentMonthTotalDomestic: 0,
      previousMonthTotalDomestic: 0,
      currentMonthTotalAbroad: 0,
      previousMonthTotalAbroad: 0,
      currentWeekTotalDomestic: 0,
      previousWeekTotalDomestic: 0,
      currentWeekTotalAbroad: 0,
      previousWeekTotalAbroad: 0,
      dailyTomanTransactions: 0,
      dailyEuroTransactions: 0,
      transactions: [],
      monthChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'linear',
            position: 'bottom',
            min: 1,
            max: 31,
            ticks: {
              callback: function(value) {
                return [1, 10, 20, 30, 31].includes(value) ? value.toString() : '';
              },
              maxRotation: 0, // Ensure the ticks are not rotated
              padding: 10,   // Add padding to the ticks
            }
          }
        },
        elements: {
          line: {
            tension: 0.4 // Add curve to lines
          }
        }
      },
      weekChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'linear',
            position: 'bottom',
            min: 1,
            max: 7,
            ticks: {
              callback: function(value) {
                const dayMap = {
                  1: 'شنبه',
                  2: 'یکشنبه',
                  3: 'دوشنبه',
                  4: 'سه‌شنبه',
                  5: 'چهارشنبه',
                  6: 'پنج‌شنبه',
                  7: 'جمعه'
                };
                return dayMap[value];
              }
            }
          }
        },
        elements: {
          line: {
            tension: 0.4 // Add curve to lines
          }
        }
      }
    };
  },
  mounted() {
     const style = document.createElement('style');
    style.innerHTML = `
      .navbar-vertical.navbar-left {
        display: block !important;
      }
      .notifBar{
      display:block!important;
      margin-top:25px;
    }
    `;
    document.head.appendChild(style);


    this.fetchAccumulativeTransactionsData();
    this.fetchTransactions();
    this.fetchCurrentDayTransactions(); // Add this line
  },
  methods: {
    fetchAccumulativeTransactionsData() {
      const token = localStorage.getItem('jwt');
      axios.get(`https://dashboard.ordmenpodcast.com/v/current_chart.php?chartType=accumulativeTransactions`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        const data = response.data.data;
        // Month data
        this.accumulativeTransactionsDataDomesticMonth = this.formatAccumulativeTransactionsData(data.domestic.currentMonth, data.domestic.previousMonth);
        this.accumulativeTransactionsDataAbroadMonth = this.formatAccumulativeTransactionsData(data.abroad.currentMonth, data.abroad.previousMonth);
        this.currentMonthTotalDomestic = this.calculateTotal(data.domestic.currentMonth);
        this.previousMonthTotalDomestic = this.calculateTotal(data.domestic.previousMonth);
        this.currentMonthTotalAbroad = this.calculateTotal(data.abroad.currentMonth);
        this.previousMonthTotalAbroad = this.calculateTotal(data.abroad.previousMonth);
        // Week data
        this.accumulativeTransactionsDataDomesticWeek = this.formatAccumulativeTransactionsData(data.domestic.currentWeek, data.domestic.previousWeek);
        this.accumulativeTransactionsDataAbroadWeek = this.formatAccumulativeTransactionsData(data.abroad.currentWeek, data.abroad.previousWeek);
        this.currentWeekTotalDomestic = this.calculateTotal(data.domestic.currentWeek);
        this.previousWeekTotalDomestic = this.calculateTotal(data.domestic.previousWeek);
        this.currentWeekTotalAbroad = this.calculateTotal(data.abroad.currentWeek);
        this.previousWeekTotalAbroad = this.calculateTotal(data.abroad.previousWeek);
        this.loaded = true;
      })
      .catch(error => {
        console.error(error);
        this.loaded = true;
      });
    },
    formatAccumulativeTransactionsData(currentData, previousData) {
      const current = currentData.map(item => ({ x: item.day, y: item.total_sales }));
      const previous = previousData.map(item => ({ x: item.day, y: item.total_sales }));
      
      return {
        datasets: [
          {
            label: 'تراکنش های موفق هفته جاری',
            data: current,
            borderColor: 'rgba(75, 192, 192, 1)',
            fill: false
          },
          {
            label: 'تراکنش های موفق هفته گذشته',
            data: previous,
            borderColor: 'rgba(192, 192, 192, 1)',
            fill: false
          }
        ]
      };
    },
    calculateTotal(data) {
      return data.reduce((sum, item) => sum + parseFloat(item.total_sales), 0); // Parse amount as float
    },
    fetchTransactions() {
      const token = localStorage.getItem('jwt');
      axios.get('https://dashboard.ordmenpodcast.com/v/list_transactions.php', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        this.transactions = response.data;
      })
      .catch(error => {
        console.error(error);
      });
    },
    getYesNo(value) {
      return value === '1' ? 'بله' : 'خیر';
    },
    euroOrTomans(value) {
      return value === '1' ? 'یورو' : 'تومان';
    },
    splitCreatedAt(dateString, i) {
      if (dateString) {
        return dateString.split(' - ')[i];
      }
      return '';
    },
    fetchCurrentDayTransactions() { // Add this method
      const token = localStorage.getItem('jwt');
      axios.get('https://dashboard.ordmenpodcast.com/v/current_day_transactions.php', {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        this.dailyTomanTransactions = response.data.daily_toman_transactions;
        this.dailyEuroTransactions = response.data.daily_euro_transactions;
      })
      .catch(error => {
        console.error(error);
      });
    },
    formatNum(number) {
      // Convert the float to an integer
      let intNumber = Math.floor(number);

      // Convert the number to a string and add commas
      let formattedNumber = intNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "،");

      return formattedNumber;
    }
  },
  computed: {
    paginatedTransactionsDomestic() {
      return this.transactions.filter(transaction => transaction.abroad === '0').slice(-6);
    },
    paginatedTransactionsAbroad() {
      return this.transactions.filter(transaction => transaction.abroad === '1').slice(-6);
    }
  }
};
</script>

<style scoped>
@media (min-width: 1600px) {
  .container {
    width: 90%!important;
    margin-top: 30px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.col-lg-12, .col-md-12 {
  padding: 10px;
}
.chart-container {
  position: relative;
  height: 250px; /* Reduced height from 400px to 320px */
  overflow: visible; /* Ensure content overflow is visible */
}
h3 {
  text-align: center;
}
.legend-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.legend-item {
  margin: 0 10px;
  display: flex;
  align-items: center;
}
.legend-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 5px;
}
</style>
