<template>
    <div class="container">
      <div class="row">
        <!-- Left side - Selection of Webinars and Products -->
        <div class="col-lg-6">
          <h2>انتخاب گروه</h2>
  
          <!-- Webinar selection with search -->
          <label>انتخاب وبینار</label>
          <input
            type="text"
            v-model="webinarSearch"
            class="form-control mb-3"
            placeholder="جستجوی وبینار"
          />
          <select v-model="selectedWebinars" multiple @change="fetchAttendees">
            <option v-for="webinar in filteredWebinars" :key="webinar.id" :value="webinar">
              {{ webinar.title }} - بار {{ webinar.nth_course }}
            </option>
          </select>
  
          <!-- Product selection with search -->
          <label>انتخاب محصول</label>
          <input
            type="text"
            v-model="productSearch"
            class="form-control mb-3"
            placeholder="جستجوی محصول"
          />
          <select v-model="selectedProducts" multiple @change="fetchAttendees">
            <option v-for="product in filteredProducts" :key="product.id" :value="product">
              {{ product.title }}
            </option>
          </select>
  
          <!-- Members Table -->
          <h3>لیست اعضا</h3>
          <div v-if="filteredMembers.length > 0">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" @change="toggleSelectAll($event)" />
                  </th>
                  <th>#</th>
                  <th>نام</th>
                  <th>ایمیل</th>
                  <th>منبع</th>
                  <th>عملیات</th> <!-- New column for operations -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(member, index) in filteredMembers" :key="member.email">
                  <td>
                    <input type="checkbox" v-model="selectedMembers" :value="member" />
                  </td>
                  <td>{{ index + 1 }}</td>
                  <td>{{ member.name }}</td>
                  <td>{{ member.email }}</td>
                  <td>{{ member.source }}</td>
                  <td>{{ member.emailStatus || '' }}</td> <!-- Show the status -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        <!-- Right side - CKEditor for message writing -->
        <div class="col-lg-6">
          <h2>ارسال پیام</h2>
  
          <!-- Subject Input Field -->
          <input
            type="text"
            v-model="emailSubject"
            class="form-control mb-3"
            placeholder="موضوع ایمیل"
          />
  
          <!-- CKEditor -->
          <ckeditor :editor="editor" v-model="messageContent" :config="editorConfig"></ckeditor>
  
          <!-- Send Email Button -->
          <button class="btn btn-primary mt-3" @click="sendMessages">ارسال پیام</button>
        </div>
      </div>
    </div>
  </template>


  <script>
import axios from 'axios';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { htmlToText } from 'html-to-text';

export default {
  data() {
    return {
      webinars: [],
      products: [],
      members: [],
      selectedWebinars: [],
      selectedProducts: [],
      selectedMembers: [],
      webinarSearch: '', // Add a search query for webinars
      productSearch: '', // Add a search query for products
      messageContent: '', // Email content from CKEditor
      emailSubject: '', // Subject for the email
      editor: ClassicEditor,
      editorConfig: {
        language: 'ar', // Set the language to Arabic (or any RTL language)
        contentsLangDirection: 'rtl', // Set the content direction to RTL
        alignment: {
          options: ['right', 'left'] // Only allow right alignment
        }
      }
    };
  },
  computed: {
    filteredWebinars() {
      // Filter webinars based on the search query
      return this.webinars.filter(webinar =>
        webinar.title.toLowerCase().includes(this.webinarSearch.toLowerCase())
      );
    },
    filteredProducts() {
      // Filter products based on the search query
      return this.products.filter(product =>
        product.title.toLowerCase().includes(this.productSearch.toLowerCase())
      );
    },
    filteredMembers() {
      // Show all members if no webinars or products are selected
      if (this.selectedWebinars.length === 0 && this.selectedProducts.length === 0) {
        return this.members;
      }

      // Otherwise, filter members based on selected webinars/products
      const attendeesEmails = new Set();

      this.selectedWebinars.forEach(webinar => {
        webinar.attendants.split(', ').forEach(email => attendeesEmails.add(email));
      });

      this.selectedProducts.forEach(product => {
        product.attendants.split(', ').forEach(email => attendeesEmails.add(email));
      });

      return this.members.filter(member => attendeesEmails.has(member.email));
    }
  },
  methods: {
    fetchWebinars() {
      const token = localStorage.getItem('jwt');
      axios
        .get('https://dashboard.ordmenpodcast.com/v/list_webinars.php', {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          this.webinars = response.data.webinars || [];
        })
        .catch(error => {
          console.error('Error fetching webinars:', error);
        });
    },
    fetchProducts() {
      const token = localStorage.getItem('jwt');
      axios
        .get('https://dashboard.ordmenpodcast.com/v/list_products.php', {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          this.products = response.data.webinars || [];
        })
        .catch(error => {
          console.error('Error fetching products:', error);
        });
    },
    fetchMembers() {
      const token = localStorage.getItem('jwt');
      axios
        .get('https://dashboard.ordmenpodcast.com/v/robot_users_info.php', {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          this.members = response.data.map(user => ({
            name: user.FirstNameLastName,
            email: user.EMailAddress,
            source: user.Source === 'Robot' ? 'ربات' : 'وبسایت',
            emailStatus: '' // Set initial status for each member
          }));
        })
        .catch(error => {
          console.error('Error fetching members:', error);
        });
    },
    fetchAttendees() {
      // Fetch attendees for selected webinars/products
      this.fetchMembers();
    },
    sendMessages() {
      this.selectedMembers.forEach(member => {
        // Update status to "Sending message" or "Sending email"
        member.emailStatus = member.source === 'ربات' ? 'در حال ارسال پیام' : 'در حال ارسال ایمیل';

        if (member.source === 'ربات') {
          this.sendTelegramMessage(member);
        } else {
          this.sendEmail(member);
        }
      });
    },
    sendTelegramMessage(member) {
      const token = localStorage.getItem('jwt');
      const telegramMessage = `
        ${member.name} عزیز سلام؛\n
        ${htmlToText(this.messageContent, {
          wordwrap: 130,
          uppercaseHeadings: false,
          singleNewLineParagraphs: true,
          tags: { // Optional custom tag handling
            'a': { format: 'inline' },
            'p': { format: 'block' }
          }
        })}
      `;

      axios
        .post(
          'https://dashboard.ordmenpodcast.com/v/send_telegram_using_email_message.php',
          {
            email: member.email, // Use email in the request payload
            text: telegramMessage
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(response => {
          member.emailStatus = response.data.success ? 'پیام ارسال شد' : 'پیام ارسال نشد';
        })
        .catch(() => {
          member.emailStatus = 'پیام ارسال نشد';
        });
    },
    sendEmail(member) {
      const token = localStorage.getItem('jwt');

      const emailContent = `
        <div style='text-align:right!important;direction:rtl!important;font-family:Tahoma!important;font-size:13px;'>
        ${member.name} عزیز سلام؛
        <br><br>
        ${this.messageContent}
        </div>
      `;
      axios
        .post(
          'https://dashboard.ordmenpodcast.com/v/send_email.php',
          {
            name: member.name, // Send the member's name
            email: member.email, // Send the member's email
            subject: this.emailSubject, // Send the subject
            content: emailContent // Send the content from CKEditor
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(response => {
          member.emailStatus = response.data.success ? 'ایمیل ارسال شد' : 'ایمیل ارسال نشد';
        })
        .catch(() => {
          member.emailStatus = 'ایمیل ارسال نشد';
        });
    },
    toggleSelectAll(event) {
      if (event.target.checked) {
        this.selectedMembers = [...this.filteredMembers];
      } else {
        this.selectedMembers = [];
      }
    }
  },
  mounted() {
    this.fetchWebinars();
    this.fetchProducts();
    this.fetchMembers(); // Fetch all members when component is mounted
  }
};
</script>




  <style scoped>
  .container {
    margin-top: 20px;
  }
  select {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .ck-editor__editable {
    min-height: 250px;
  }
  </style>
  