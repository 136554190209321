import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '../components/UserLogin.vue';
import SecurePage from '../components/SecurePage.vue';
import SettingPage from '../components/SettingPage.vue';
import UserLogout from '../components/UserLogout.vue';
import ListWebinars from '../components/ListWebinars.vue';
import ListContacts from '../components/ListContacts.vue';
import ListDiscounts from '../components/ListDiscounts.vue';
import ListMentorships from '../components/ListMentorships.vue';
import ListMentorshipQuestions from '../components/ListMentorshipQuestions.vue';
import ListMentorshipAnswers from '../components/ListMentorshipAnswers.vue';
import AnalyticalCharts from '../components/AnalyticalCharts.vue';
import RobotUsersInfo from '../components/RobotUsersInfo.vue';
import Transactions from '../components/TransactionsPage.vue';
import AccountingPage from '../components/AccountingPage.vue';
import Pocket from '../components/PocketPage.vue';
import MarketingPage from '../components/MarketingPage.vue';




const routes = [
  { path: '/', component: UserLogin },
  { path: '/dashboard', component: SecurePage }, // Update the route to use /dashboard
  { path: '/setting', component: SettingPage }, // Update the route to use /dashboard
  { path: '/logout', component: UserLogout },
  { path: '/transactions', component: Transactions },
  { path: '/accounting', component: AccountingPage },
  { path: '/robotusers', component: RobotUsersInfo },
  { path: '/webinars', component: ListWebinars },
  { path: '/discounts', component: ListDiscounts },
  { path: '/contacts', component: ListContacts },
  { path: '/marketing', component: MarketingPage },
  { path: '/mentorships', component: ListMentorships },
  { path: '/mentorshipquestions', component: ListMentorshipQuestions },
  { path: '/mentorshipanswers', component: ListMentorshipAnswers },
  { path: '/charts', component: AnalyticalCharts },
  { path: '/wallets', component: Pocket },
  { path: '/login', name: 'UserLogin', component: UserLogin }
];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });
  
export default router;
