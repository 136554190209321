<template>
  <div class="container mt-5">
  

        <div class="col-lg-12" style="margin-bottom:25px;">
          <div class="card" style="padding:10px 15px;border-radius:50px;">
            <div style="float:right;width:100%;">
                <div style="float:right;width:40%">
                  
                  </div> 


               <div style="float:left;width:20%">
    
          <input
            type="text"
            v-model="searchQuery"
            placeholder="جستجو: نام، ایمیل"
            class="form-control form-search"
          />

                </div>
              </div>
          </div>
        </div>




    <div class="row">
      <div class="col-md-10 card" style="padding-top:17px;margin-bottom:15px;">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">ایمیل</th>
                <th scope="col">نام</th>
                <th scope="col">
                  تاریخ
                  <button @click="toggleSort('created_at')" class="btn btn-link btn-sm">
                    <svg v-if="sortOrder === 'asc' && sortKey === 'created_at'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-down">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <line x1="12" y1="5" x2="12" y2="19"/>
                      <line x1="18" y1="13" x2="12" y2="19"/>
                      <line x1="6" y1="13" x2="12" y2="19"/>
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-up">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <line x1="12" y1="5" x2="12" y2="19"/>
                      <line x1="18" y1="11" x2="12" y2="5"/>
                      <line x1="6" y1="11" x2="12" y2="5"/>
                    </svg>
                  </button>
                </th>

                <th scope="col">کد محصول</th>
                <th scope="col">مبلغ</th>
                <th scope="col">جغرافیا</th>
                <th scope="col">موضوع</th>
                <th scope="col">سطح فوریت
                  <button @click="toggleSort('emergency')" class="btn btn-link btn-sm">
                    <svg v-if="sortOrder === 'asc' && sortKey === 'emergency'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-down">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <line x1="12" y1="5" x2="12" y2="19"/>
                      <line x1="18" y1="13" x2="12" y2="19"/>
                      <line x1="6" y1="13" x2="12" y2="19"/>
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icon-tabler-arrow-up">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <line x1="12" y1="5" x2="12" y2="19"/>
                      <line x1="18" y1="11" x2="12" y2="5"/>
                      <line x1="6" y1="11" x2="12" y2="5"/>
                    </svg>
                  </button>
                </th>
                <th scope="col">اطلاعات بیشتر</th>
                <th scope="col">تکمیل</th>
                <th scope="col">کسر از کیف پول</th>
                <th scope="col">ارسال تلگرام</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="contact in filteredContacts"
                :key="contact.id"
                :class="{'done-row': contact.done == 1}"
              >
                <td>{{ contact.email }}</td>
                <td>{{ contact.name }}</td>
                <td>{{ contact.created_at }}</td>

                <td>{{ contact.product_id }}</td>
                <td>{{ contact.amount }}</td>
                <td>{{ contact.abroad == 1 ? 'خارجی' : 'ایرانی' }}</td>
                <td>{{ contact.title }}</td>
                <td>
                  <span
                    :class="{
                      'badge bg-danger': contact.emergency === 'high',
                      'badge bg-warning': contact.emergency === 'medium',
                      'badge bg-success': contact.emergency === 'low',
                    }"
                  >
                    {{ getEmergencyLabel(contact.emergency) }}
                  </span>
                </td>
                <td>
                  <button
                    class="btn btn-primaryy"
                    @click="showMoreInfo(contact)"
                  >
                    بیشتر
                  </button>
                </td>
                <td>
                  <button v-if="contact.done != 1"
                    class="btn btn-primaryy btn-success"
                    @click="confirmCompletion(contact)"
                  >
                    تکمیل
                  </button>
                </td>
                
                <td> 
                  <button  
  v-if="contact.title.includes('نقد کردن کیف پول') && contact.done != 1"
  class="btn btn-primaryy btn-warning"
  @click="confirmWalletUpdate(contact)"
>
  کسر
</button>

                </td>


                <td>
                  <button class="btn btn-primaryy btn-sm" @click="showCustomTelegramModal(contact.email)">
                    پیام تلگرام
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


      <div class="col-md-2">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">فیلترها</h5>
            <div class="form-group">
              <label>سطح فوریت:</label>
              <select v-model="filters.emergency" class="form-control mb-2">
                <option value="">همه</option>
                <option value="high">بالا</option>
                <option value="medium">متوسط</option>
                <option value="low">پایین</option>
              </select>
            </div>
            <div class="form-group">
              <label>جغرافیا:</label>
              <select v-model="filters.abroad" class="form-control mb-2">
                <option value="">همه</option>
                <option value="1">خارجی</option>
                <option value="0">ایرانی</option>
              </select>
            </div>
            <div class="form-group">
              <label>موضوع:</label>
              <select v-model="filters.title" class="form-control mb-2">
                <option value="">همه</option>
                <option v-for="title in distinctTitles" :key="title" :value="title">
                  {{ title }}
                </option>
              </select>
            </div>
            <button @click="resetFilters" class="btn btn-secondary mt-2">
              حذف فیلترها
            </button>
          </div>
        </div>
      </div>


    </div>


    <div class="modal fade" id="customTelegramModal" tabindex="-1" role="dialog" aria-labelledby="customTelegramModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="customTelegramModalLabel">ارسال پیام تلگرام دلخواه</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="customTelegramContent">محتوا</label>
              <ckeditor :editor="editor" v-model="customTelegramContent" :config="editorConfig" class="custom-editor" style="min-height: 300px; width: 600px;"></ckeditor>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">انصراف</button>
            <button type="button" class="btn btn-primary" @click="sendCustomTelegramMessages">ارسال پیام</button>
          </div>
        </div>
      </div>
    </div>


    <!-- More Info Modal -->
    <div
      class="modal fade"
      id="moreInfoModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="moreInfoModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="moreInfoModalLabel">اطلاعات بیشتر</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="selectedContact">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>ویژگی</th>
                    <th>مقدار</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="selectedContact.email">
                    <td>ایمیل</td>
                    <td>{{ selectedContact.email }}</td>
                  </tr>
                  <tr v-if="selectedContact.name">
                    <td>نام</td>
                    <td>{{ selectedContact.name }}</td>
                  </tr>
                  <tr v-if="selectedContact.product_id">
                    <td>کد محصول</td>
                    <td>{{ selectedContact.product_id }}</td>
                  </tr>
                  <tr v-if="selectedContact.amount">
                    <td>مبلغ</td>
                    <td>{{ selectedContact.amount }}</td>
                  </tr>
                  <tr v-if="selectedContact.abroad">
                    <td>جغرافیا</td>
                    <td>{{ selectedContact.abroad == 1 ? 'خارجی' : 'ایرانی' }}</td>
                  </tr>
                  <tr v-if="selectedContact.title">
                    <td>موضوع</td>
                    <td>{{ selectedContact.title }}</td>
                  </tr>
                  <tr v-if="selectedContact.paid">
                    <td>پرداخت شده</td>
                    <td>{{ selectedContact.paid }}</td>
                  </tr>
                  <tr v-if="selectedContact.hashmd5">
                    <td>هش</td>
                    <td>{{ selectedContact.hashmd5 }}</td>
                  </tr>
                  <tr v-if="selectedContact.mentorship">
                    <td>مشاوره</td>
                    <td>{{ selectedContact.mentorship }}</td>
                  </tr>
                  <tr v-if="selectedContact.drop_tr">
                    <td>آیا قبلا استرداد شده؟</td>
                    <td>{{ selectedContact.drop_tr }}</td>
                  </tr>
                  <tr v-if="selectedContact.created_at">
                    <td>تاریخ ایجاد</td>
                    <td>{{ selectedContact.created_at }}</td>
                  </tr>
                  <tr v-if="selectedContact.walletCharge">
                    <td>شارژ کیف پول</td>
                    <td>{{ selectedContact.walletCharge }}</td>
                  </tr>
                  <tr v-if="selectedContact.phone_number">
                    <td>شماره تلفن</td>
                    <td>{{ selectedContact.phone_number }}</td>
                  </tr>
                  <tr v-if="selectedContact.chatID">
                    <td>شناسه چت</td>
                    <td>{{ selectedContact.chatID }}</td>
                  </tr>
                  <tr v-if="selectedContact.nth_time">
                    <td>بار چندم؟</td>
                    <td>{{ selectedContact.nth_time }}</td>
                  </tr>
                  <tr v-if="selectedContact.reg_channel">
                    <td>کانال ثبت‌نام</td>
                    <td>{{ selectedContact.reg_channel }}</td>
                  </tr>
                  <tr v-if="selectedContact.offline">
                    <td>آفلاین؟</td>
                    <td>{{ selectedContact.offline }}</td>
                  </tr>
                  <tr v-if="selectedContact.card_pan">
                    <td>شماره کارت</td>
                    <td>{{ selectedContact.card_pan }}</td>
                  </tr>
                  <tr v-if="selectedContact.ref_id">
                    <td>شناسه مرجع</td>
                    <td>{{ selectedContact.ref_id }}</td>
                  </tr>
                  <tr v-if="selectedContact.donation">
                    <td>حمایت مالی</td>
                    <td>{{ selectedContact.donation }}</td>
                  </tr>
                  <tr v-if="selectedContact.refund_to_bank">
                    <td>بازپرداخت به بانک</td>
                    <td>{{ selectedContact.refund_to_bank }}</td>
                  </tr>
                  <tr v-if="selectedContact.subject">
                    <td>موضوع</td>
                    <td>{{ selectedContact.subject }}</td>
                  </tr>
                  <tr v-if="selectedContact.content">
                    <td>محتوا</td>
                    <td v-html="formatContent(selectedContact.content)"></td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>

          


          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              بستن
            </button>
          </div>
        </div>
      </div>
    </div>


    <!-- Confirm Completion Modal -->
    <div
      class="modal fade"
      id="confirmCompletionModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="confirmCompletionModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmCompletionModalLabel">تایید تکمیل</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
<!--             
            <p>کیف پول: {{ walletName }}</p>
            <p>مقدار کنونی کیف پول: {{ currentWalletValue }}</p>
            <p>مقدار جدید کیف پول: {{ updatedWalletValue }}</p>
            <p>آیا مطمئن هستید که می‌خواهید این مقدار را به کیف پول اضافه کنید؟</p> -->
            <p>آیا مطمئن هستید که می‌خواهید این تماس را تکمیل کنید؟</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              انصراف
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="completeContact"
            >
              تکمیل
            </button>
          </div>
        </div>
      </div>
    </div>

<!-- Subtract Amount Modal -->
<div
  class="modal fade"
  id="subtractAmountModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="subtractAmountModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="subtractAmountModalLabel">کسر از کیف پول</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>مقدار کنونی کیف پول ریال: {{ iranWalletValue }}</p>
        <p>مقدار کنونی کیف پول یورو: {{ euroWalletValue }}</p>
        <div class="form-group">
          <label for="walletType">انتخاب کیف پول:</label>
          <select v-model="selectedWallet" class="form-control">
            <option value="IranWallet">کیف پول ریال</option>
            <option value="EuroWallet">کیف پول یورو</option>
          </select>
        </div>
        <div class="form-group mt-3">
          <label for="subtractAmount">مبلغ:</label>
          <input type="number" v-model="subtractAmount" class="form-control" />
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          انصراف
        </button>
        <button type="button" class="btn btn-info" @click="showConfirmModal">
          ادامه
        </button>
      </div>
    </div>
  </div>
</div>


<!-- Confirm Wallet Update Modal -->
<div
  class="modal fade"
  id="confirmWalletUpdateModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="confirmWalletUpdateModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="confirmWalletUpdateModalLabel">تایید کسر از کیف پول</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>کیف پول: {{ selectedWallet === 'IranWallet' ? 'کیف پول ریال' : 'کیف پول یورو' }}</p>
        <p>مقدار کنونی: {{ selectedWallet === 'IranWallet' ? iranWalletValue : euroWalletValue }}</p>
        <p>مقدار جدید: {{ updatedWalletValue }}</p>
        <p>آیا مطمئن هستید که می‌خواهید این مقدار را کسر کنید؟</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          انصراف
        </button>
        <button
          type="button"
          class="btn btn-info"
          @click="updateWallet"
        >
          تایید
        </button>
      </div>
    </div>
  </div>
</div>




    <!-- Toast -->
    <div class="toast-container position-fixed bottom-0 end-0 p-3">
      <div id="copyToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">عملیات</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ toastMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Modal, Toast } from 'bootstrap';
import jalaali from 'jalaali-js';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { htmlToText } from 'html-to-text';


export default {
  data() {
    return {
      contacts: [],
      searchQuery: '',
      filters: {
        emergency: '',
        abroad: '',
        title: '',
      },
      customTelegramContent: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'ar', // Set the language to Arabic (or any RTL language)
        contentsLangDirection: 'rtl', // Set the content direction to RTL
        alignment: {
          options: ['right', 'left'] // Only allow right alignment
        }
      },
      selectedEmail: '',
      selectedChatID: '',
      walletColumn: '',
      customTelegramInstance: null,
      selectedContact: null,
      modalInstance: null,
      confirmModalInstance: null,
      currentWalletValue: 0,
      updatedWalletValue: 0,
      iranWalletValue: 0,
      euroWalletValue: 0,
      selectedWallet: 'IranWallet',
      subtractAmount: 0,
      walletName: '',
      sortKey: '',
      sortOrder: '',
      walletAction: 'add',
      toastMessage: '',
    };
  },
  computed: {
    distinctTitles() {
      return [...new Set(this.contacts.map(contact => contact.title))];
    },
    filteredContacts() {
      return this.contacts.filter((contact) => {
        return (
          (this.searchQuery === '' ||
            contact.email.includes(this.searchQuery) ||
            contact.name.includes(this.searchQuery)) &&
          (this.filters.emergency === '' ||
            contact.emergency === this.filters.emergency) &&
          (this.filters.abroad === '' || contact.abroad == this.filters.abroad) &&
          (this.filters.title === '' || contact.title === this.filters.title)
        );
      });
    },
  },
  methods: {
 formatContent(content) {
    // Replace newlines with <br> and make lines with colons or question marks bold
    return content
      .split('\n')
      .map(line => line.includes(':') || line.includes('?') || line.includes('؟') ? `<strong>${line}</strong>` : line)
      .join('<br>');
  },
    fetchContacts() {
      const token = localStorage.getItem('jwt');
      axios
        .post('https://dashboard.ordmenpodcast.com/v/contacts.php', {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.contacts = response.data;
        })
        .catch((error) => {
          console.error('Error fetching contacts:', error);
        });
    },
    getEmergencyLabel(emergency) {
      switch (emergency) {
        case 'high':
          return 'بالا';
        case 'medium':
          return 'متوسط';
        case 'low':
          return 'پایین';
        default:
          return '';
      }
    },
    showMoreInfo(contact) {
      this.selectedContact = contact;
      const modalElement = document.getElementById('moreInfoModal');
      this.modalInstance = new Modal(modalElement);
      this.modalInstance.show();
    },
    confirmCompletion(contact) {
      this.selectedContact = contact;
      const modalElement = document.getElementById('confirmCompletionModal');
      this.confirmModalInstance = new Modal(modalElement);
      this.confirmModalInstance.show();
    },
    async completeContact() {
  const token = localStorage.getItem('jwt');

  try {
    // First axios request to get dashboard data
    const response = await axios.post(
      'https://dashboard.ordmenpodcast.com/v/get_dashboard.php',
      {
        chatId: this.selectedContact.chatId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Set the wallet column, name, and values
    this.walletColumn =
      this.selectedContact.abroad == 1 ? 'EuroWallet' : 'IranWallet';
    this.walletName =
      this.selectedContact.abroad == 1 ? 'کیف پول یورویی' : 'کیف پول تومانی';
    this.currentWalletValue = parseInt(response.data[this.walletColumn], 10);
    this.updatedWalletValue =
      this.walletAction === 'add'
        ? this.currentWalletValue + parseInt(this.selectedContact.amount, 10)
        : this.currentWalletValue - parseInt(this.selectedContact.amount, 10);

    console.log(this.walletColumn);
    console.log(this.walletName);
    console.log(this.currentWalletValue);
    console.log(this.updatedWalletValue);

    // Perform the actions based on the title
    if (this.selectedContact.title === 'استرداد وجه به وبینار به کیف پول' || this.selectedContact.title === 'استرداد وجه مشاوره به کیف پول') {
      this.walletAction = 'add';

      const changeResponse = await axios.post(
        'https://dashboard.ordmenpodcast.com/v/change_dashboard.php',
        {
          chatId: this.selectedContact.chatId,
          column: this.walletColumn,
          value: this.updatedWalletValue,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (changeResponse.data.success) {
        const actionMessage = this.walletAction === 'add' ? 'اضافه شد' : 'کسر شد';
        this.showToast(
          `مقدار ${actionMessage} از ${this.walletName} با موفقیت انجام شد.`
        );
      } else {
        console.error('Error updating dashboard:', changeResponse.data.error);
      }
    }

    // Mark the contact as done
    const contactResponse = await axios.post(
      'https://dashboard.ordmenpodcast.com/v/change_contact.php',
      {
        id: this.selectedContact.id,
        column: 'done',
        value: 1,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (contactResponse.data.success) {
      this.selectedContact.done = 1;
      this.confirmModalInstance.hide();
      this.fetchContacts(); // Fetch the updated contacts
      this.showToast('تماس با موفقیت تکمیل شد.');
    } else {
      console.error('Error updating contact:', contactResponse.data.error);
    }
  } catch (error) {
    console.error('Error:', error);
  }
  },

    resetFilters() {
      this.filters = {
        emergency: '',
        abroad: '',
        title: '',
      };
    },
    
  confirmWalletUpdate(contact) {
    this.selectedContact = contact;
    const token = localStorage.getItem('jwt');

    axios.post('https://dashboard.ordmenpodcast.com/v/get_dashboard.php', {
      chatId: contact.chatId,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      this.iranWalletValue = parseInt(response.data['IranWallet'], 10);
      this.euroWalletValue = parseInt(response.data['EuroWallet'], 10);

      const modalElement = document.getElementById('subtractAmountModal');
      this.confirmWalletUpdateModalInstance = new Modal(modalElement);
      this.confirmWalletUpdateModalInstance.show();
    })
    .catch((error) => {
      console.error('Error fetching wallet data:', error);
    });
  },

  showConfirmModal() {
    const selectedWalletValue = this.selectedWallet === 'IranWallet' 
      ? this.iranWalletValue 
      : this.euroWalletValue;

    this.updatedWalletValue = selectedWalletValue - this.subtractAmount;


    // Close the subtractAmountModal
    const subtractModalElement = document.getElementById('subtractAmountModal');
    const subtractModalInstance = Modal.getInstance(subtractModalElement);
    subtractModalInstance.hide();


    const modalElement = document.getElementById('confirmWalletUpdateModal');
    this.confirmWalletUpdateModalInstance = new Modal(modalElement);
    this.confirmWalletUpdateModalInstance.show();
  },

  updateWallet() {
    const token = localStorage.getItem('jwt');
    axios.post('https://dashboard.ordmenpodcast.com/v/change_dashboard.php', {
      chatId: this.selectedContact.chatId,
      column: this.selectedWallet,
      value: this.updatedWalletValue,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response.data.success) {
        this.confirmWalletUpdateModalInstance.hide();
        this.fetchContacts(); // Refresh the list
        this.showToast(`مقدار کسر شده از ${this.selectedWallet === 'IranWallet' ? 'کیف پول ریال' : 'کیف پول یورو'} با موفقیت انجام شد.`);
      } else {
        console.error('Error updating wallet:', response.data.error);
      }
    })
    .catch((error) => {
      console.error('Error updating wallet:', error);
    });
  },


    showCustomTelegramModal(email) {
      this.selectedChatID = email;
      const modalElement = document.getElementById('customTelegramModal');
      this.customTelegramInstance = new Modal(modalElement);
      this.customTelegramInstance.show();
    },
    hideCustomTelegramModal() {
      if (this.customTelegramInstance) {
        this.customTelegramInstance.hide();
      }
    },
    sendCustomTelegramMessages() {
      const token = localStorage.getItem('jwt');
      const telegramMessage = htmlToText(this.customTelegramContent, {
        wordwrap: 130,
        uppercaseHeadings: false,
        singleNewLineParagraphs: true,
        tags: {
          'a': { format: 'inline' },
          'p': { format: 'block' }
        }
      });
      axios.post('https://dashboard.ordmenpodcast.com/v/send_telegram_using_email_message.php', {
        email: this.selectedChatID,
        text: telegramMessage
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          if (response.data.success) {
            this.toastMessage = 'پیام تلگرام با موفقیت ارسال شد!';
            this.showToast();
            this.hideCustomTelegramModal();
          } else {
            this.error = response.data.error || 'Failed to send Telegram message';
          }
        })
        .catch(error => {
          this.error = error.response ? error.response.data.error : 'پاسخی از سرور دریافت نشد. لطفا بعدا دوباره تلاش کنید.';
        });
    },

    showToast(message) {
      this.toastMessage = message;
      const toastElement = document.getElementById('copyToast');
      const toast = new Toast(toastElement);
      toast.show();
    },
      toggleSort(key) {
        if (this.sortKey === key) {
          this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortKey = key;
          this.sortOrder = 'asc';
        }
        this.sortContacts();
      },
      sortContacts() {
        this.filteredContacts.sort((a, b) => {
          let result = 0;
          if (this.sortKey === 'created_at') {
            const dateA = this.convertToGregorian(a.created_at);
            const dateB = this.convertToGregorian(b.created_at);
            result = new Date(dateA) - new Date(dateB);
          } else if (this.sortKey === 'emergency') {
            const emergencyLevels = { 'low': 1, 'medium': 2, 'high': 3 };
            result = emergencyLevels[a.emergency] - emergencyLevels[b.emergency];
          }
          return this.sortOrder === 'asc' ? result : -result;
        });
      },
      convertToGregorian(shamsiDate) {
        const [date, time] = shamsiDate.split(' ');
        const [jy, jm, jd] = date.split('-').map(Number);
        const { gy, gm, gd } = jalaali.toGregorian(jy, jm, jd);
        return `${gy}-${gm.toString().padStart(2, '0')}-${gd.toString().padStart(2, '0')} ${time}`;
      }



  },
  mounted() {
    this.fetchContacts();
  },
};
</script>

<style scoped>
.container {
  max-width: 1000px;
}

.markdown>table, .table {
    --tblr-table-color: inherit;
    --tblr-table-bg: transparent;
    --tblr-table-border-color: var(--tblr-border-color-translucent);
    --tblr-table-accent-bg: transparent;
    --tblr-table-striped-color: inherit;
    --tblr-table-striped-bg: transparent!important;
    --tblr-table-active-color: inherit;
    --tblr-table-active-bg: transparent!important;
    --tblr-table-hover-color: inherit;
    --tblr-table-hover-bg: transparent!important;
    width: 100%;
    margin-bottom: 1rem;
    color: var(--tblr-table-color);
    vertical-align: top;
    border-color: var(--tblr-table-border-color);
}

.done-row {
  background-color: #e0ffe0!important;
}

.card {
  margin-top: 20px;
}

@media (min-width: 1600px) {
  .container {
    max-width: 1550px;
  }
}
</style>
